
export const state = {
  pageValue : {
    currentPage : 1,
    qCari : '',
    pageName: '',
  }
}

export const mutations = {
  SET_PAGE_VALUE(state, data) {
    state.pageValue = data
  },
}


export const getters = {
    getPageValue (state){
      return state.pageValue;
    },

}
  
export const actions = {
    
    setPageValue({ commit, state, rootState }, { pageValue }) {
      commit('SET_PAGE_VALUE', pageValue);
    },
  
}


