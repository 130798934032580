const homeRoutes = [{
  path: '/',
  name: 'Home',
  header: 'home',
  icon: 'pie-chart',
  component: () => lazyLoadView(import('@views/pages/home')),
  meta: {
    authRequired: false
  },
}, ]

const mainRoutes = [
  ...homeRoutes,
]

const allRoutes = [
  ...mainRoutes,
]

export {
  allRoutes,
  mainRoutes
}


function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: require('@components/_loading').default,
    delay: 400,
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, {
      data,
      children
    }) {
      return h(AsyncHandler, data, children)
    },
  })
}