<script>
export default {
  components: {},
  created: () => {},
}
</script>

<template>
  <div>
    <div class="main">
      <div class="logo-company">
        <img src="@assets/images/logo.png" alt />
      </div>
      <div class="container">
        <slot />
      </div>
    </div>
  </div>
</template>
