import axios from 'axios'
import broker from '@utils/broker'


export const state = {
  currentUser: getSavedState('auth.currentUser'),
  modalPassword: false,
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
    setDefaultAuthHeaders(state)
  },
  REMOVE_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
  },
  SHOW_MODAL_PASSWORD(state, modalPassword) {
    state.modalPassword = modalPassword
  }
}

export const getters = {
  loggedIn(state) {
    return !!state.currentUser
  },
  getUser(state) {
    return state.currentUser
  }
}

export const actions = {
  init({
    state,
    dispatch
  }) {
    setDefaultAuthHeaders(state)
  },

  // Logs in the current user.
  logIn({
    commit,
    dispatch,
    getters
  }, {
    userName,
    passWord
  } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return broker.fetch.post('/web/auth/login', {
      username: userName,
      password: passWord,
      password_confirmation: passWord,
    }).then(res => {
      const {
        data
      } = res.data;
      const user = data.user
      const token = data.token
      broker.setToken(token)
      commit('SET_CURRENT_USER', user)
      return user
    })

  },

  logOut({
    commit
  }) {
    commit('SET_CURRENT_USER', null)
    window.localStorage.clear();
    window.location.reload();
  },

  validate({
    commit,
    state
  }) {
    if (!state.currentUser) return Promise.resolve(null)

    return broker.fetch('/web/auth/user')
      .then(res => {
        const {
          status
        } = res;
        if (status === 200) {
          const {
            data
          } = res.data;
          const user = data.user
          // const token  = data.token
          // broker.setToken(token)
          commit('SET_CURRENT_USER', user)
          return user
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          commit('SET_CURRENT_USER', null)
          window.localStorage.clear();
        }
        return null
      });
  },

  changePassword({
    commit,
    dispatch,
    getters
  }, {
    passWord,
    confirmPassword
  } = {}) {
    return broker.fetch.post('/web/auth/change-password', {
      password: passWord,
      password_confirmation: confirmPassword,
    }).then(res => {
      return res
    })

  },

  showModalPassword({
    commit,
    state,
    rootState
  }, {
    modalPassword
  }) {
    commit('SHOW_MODAL_PASSWORD', modalPassword)
  },
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.currentUser ?
    state.currentUser.token :
    ''
}