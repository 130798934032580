import axios from "axios";

let token = window.localStorage.getItem('token');
const CancelToken = axios.CancelToken;
let cancel;

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    timeout: 100000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    cancelToken: new CancelToken(function executor(c) {
        cancel = c;
    }),
});

const instanceUpload = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    timeout: 200000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    cancelToken: new CancelToken(function executor(c) {
        cancel = c;
    }),
});

const instanceUrlencoded = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    timeout: 100000,
    headers: {
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`
    },
    cancelToken: new CancelToken(function executor(c) {
        cancel = c;
    }),
});

const instanceFormData = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    timeout: 100000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    },
    cancelToken: new CancelToken(function executor(c) {
        cancel = c;
    }),
});


const setToken = (token) => {
    window.localStorage.setItem('token', token);
    instance.defaults.headers.Authorization = `Bearer ${token}`;
    instanceUrlencoded.defaults.headers.Authorization = `Bearer ${token}`;
    instanceUpload.defaults.headers.Authorization = `Bearer ${token}`;


}

const logout = () => {
    token = null;
    window.localStorage.clear();
}

export default {
    fetch: instance,
    fetchUrlEncoded: instanceUrlencoded,
    fetchUpload: instanceUpload,
    fetchFormData: instanceFormData,
    setToken,
    logout,
    cancel,
}